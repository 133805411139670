<template>
  <section id="dashboard-ecommerce">
    <b-card
      :title="'รายละเอียดหลังบ้านค่ายเกมส์ ' + gameProvviderDetail[gp].name"
      v-for="gp in gameProviders"
      :key="`detail-${gp}`"
    >
      <b-card-body>
        <b-row class="mb-1">
          <b-col md="6">
            Link :
            <a
              :href="gameProvviderDetail[gp].url"
              target="_blank"
            >{{ gameProvviderDetail[gp].url }}</a><br><br>
            Username : {{ gameProvviderDetail[gp].username }}<br>
            Password : {{ gameProvviderDetail[gp].password }}
          </b-col>
          <b-col md="6" v-if="gameProvviderDetail[gp].vpn">
            VPN : {{ gameProvviderDetail[gp].vpn_ip }}<br>
            VPN Username : {{ gameProvviderDetail[gp].vpn_username }}<br>
            VPN Password : {{ gameProvviderDetail[gp].vpn_password }}
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BEmbed,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import gameProvidersReport from '../../../game-provider-settings'

export default {
  components: {
    BRow,
    BCol,
    BEmbed,
    BCard,
    BCardBody,
  },
  data() {
    return {
      gameProviders: [],
      gameProvviderDetail: {},
      url: '',
      title: '',
      vpn: false,
      username: '',
      password: '',
    }
  },
  watch: {
    $route(to, from) {
      this.title = `รายละเอียดหลังบ้านค่ายเกมส์ ${gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].name}`
      this.url = gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].url
      this.vpn = gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].vpn
    },
  },
  created() {
    this.gameProviders = Object.keys(gameProvidersReport)
    this.gameProvviderDetail = gameProvidersReport
    // this.title = `รายละเอียดหลังบ้านค่ายเกมส์ ${gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].name}`
    // this.url = gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].url
    // this.vpn = gameProvidersReport[this.$router.currentRoute.path.replace('/reports/game-provider-agents/', '').toUpperCase()].vpn
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
